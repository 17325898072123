
import Vue from "vue";

export default Vue.extend({
  name: "ViewMilestoneModal",
  props: {
    milestone: {
      type: Object
    },
    milestoneTypes: {
      type: Object
    }
  },
  data() {
    return {
      downloadItem(milestone: any) {
        console.log(milestone);

        if (milestone.file_type === "file") {
          const link = document.createElement("a");
          link.href = milestone.file_path;
          link.download = milestone.file.replace(
            "mentoring_program_milestones/",
            ""
          );
          link.click();
        } else {
          // @ts-ignore
          window.open(milestone.file);
        }
      }
    };
  }
});
